// import 'boxicons';

// const skillset = [
//   { name: "Flutter", logo: "flutter" },
//   { name: "Python", logo: "python" },
//   { name: "Django", logo: "django" },
//   { name: "C", logo: "c-plus-plus" },
//   { name: "HTML", logo: "html5" },
//   { name: "CSS", logo: "css3" },
//   { name: "JavaScript", logo: "javascript" },
// ];

// const Skills = () => {
//   return (
//     <>
//       <section id="skills">
//         <div className="container px-5 py-10 mx-auto">
//           <h1 data-value="Gaurav Bijwe" className="name title-font sm:text-5xl my-5 font-mono font-bold text-black mb-10">
//             My skills
//             <box-icon name="rocket" color="orange"></box-icon>
//           </h1>
//           <div className="flex flex-wrap lg:w-2/3 sm:mx-auto sm:mb-2 -mx-2 justify-center">
//             {/* Mapping over the skillset array directly */}
//             {skillset.map((skill, index) => (
//                 console.log(skill.logo);
//               <div key={index} className="p-2 sm:w-1/4 w-full">
//                 <div className="bg-gray-100 border border-violet-700 rounded-full flex py-4 h-full items-center justify-evenly">
//                   <span className="title-font font-medium text-black">
//                     {skill.name}
//                   </span>
//                   <box-icon name={skill.logo} border="square"></box-icon>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Skills;

import 'boxicons';

const skillset = [
  { name: "Flutter", logo: "flutter" },
  { name: "Python", logo: "python" },
  { name: "Django", logo: "django" },
  { name: "C", logo: "c-plus-plus" },
  { name: "HTML", logo: "html5" },
  { name: "CSS", logo: "css3" },
  { name: "JavaScript", logo: "javascript" },
];

const Skills = () => {
  return (
    <>
      <section id="skills">
        <div className="container px-5 py-10 mx-auto">
          <h1 data-value="Gaurav Bijwe" className="name title-font sm:text-5xl my-5 font-mono font-bold text-black mb-10">
            My skills
          </h1>
          <div className="flex flex-wrap lg:w-2/3 sm:mx-auto sm:mb-2 -mx-2 justify-center">
            {skillset.map((skill, index) => {
              console.log(skill.logo); // Logging the value of skill.logo
              return (
                <div key={index} className="p-2 sm:w-1/4 w-full">
                  <div className="bg-gray-100 border border-orange-300 rounded-xl flex py-4 h-full items-center justify-evenly">
                    <span className="title-font font-medium text-black">
                      {skill.name}
                    </span>
                    <p>
                      <box-icon name={skill.logo} type="logo" color="purple" size="lg" animation="flashing-hover"/>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Skills;
