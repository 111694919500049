function Navbar() {
    return (
        <header className="bg-violet-700 md:sticky top-0 z-10">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-5">
                <div className="title-font font-medium text-white mb-4 md:mb-0">
                    <a href="#about" className="text-6xl font-bold">
                        GB
                    </a>
                </div>
                
                <div className="md:ml-4 md:py-1 md:pl-4 md:border-white-700 flex flex-row">
                    <a href="#projects" className="mr-5 text-white">
                        Projects
                    </a>
                    <a href="#skills" className="mr-5 text-white">
                        Skills
                    </a>
                    <a href="#contact" className="mr-5 text-white">
                        Contact
                    </a>
                </div>
            </div>
        </header>
    )
};

export default Navbar;
