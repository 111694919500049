import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { Button } from '@chakra-ui/react'
const Contact = () => {
    return (
    
            <section id="contact" className="bg-violet-700">
            <div className="container mx-auto px-10 py-20 items-center">
                <div className="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 data-value="Gaurav Bijwe" className="name title-font sm:text-5xl my-5 font-mono font-bold text-white mb-10">
                        Find me here!
                    </h1>
                </div>
                <div className="lg:flex-grow flex flex-col gap-y-3 md:flex-row md:items-start md:text-left mb-16 md:mb-0 text-black justify-evenly">
                    <Button leftIcon={<GitHubIcon />} padding='25px'>
                        <a className='text-xl' target="_blank" href='https://github.com/gbijwe'>Github</a>
                    </Button>

                    <Button leftIcon={<LinkedInIcon />} padding='25px'>
                        <a className='text-xl' target="_blank" href='https://www.linkedin.com/in/gaurav-bijwe-a37a0924b/'>LinkedIn</a>
                    </Button>

                    <Button leftIcon={<XIcon />} padding='25px'>
                        <a className='text-xl' target="_blank" href='https://twitter.com/LilPilot'>X.com</a>
                    </Button>
                </div>
            </div>
            </section>
    )
};

export default Contact;