import { Card, CardBody, CardFooter, Text, Image, Stack, Heading, Divider, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import AOS from 'aos'

const Projects = () => {
    return (
        <>
            <section id="projects" className="bg-orange-300">
            <div className="container mx-auto px-10 py-20 items-center">
                <div className="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 data-value="Gaurav Bijwe" className="name title-font sm:text-5xl my-5 font-mono font-bold text-white mb-10">
                        Here's what I've built!
                    </h1>
                </div>
                <div className="lg:flex-grow flex flex-col gap-y-3 md:flex-row md:gap-x-3 md:items-start md:text-left mb-16 md:mb-0 text-white justify-evenly">
                
                    <Card maxW="sm" backgroundColor='#44337A' textColor='white' borderRadius='lg'>
                        <CardBody>
                            <Image
                            src='https://files.catbox.moe/jnb6u7.png'
                            borderRadius='lg'
                            />
                            <Stack mt='6' spacing='3'>
                            <Heading size='xl'>MediVault</Heading>
                            <Text>
                            MediVault is a free EHR app, where users can keep track of their own hospital visits, prescriptions and medical history, and share it with new doctors and clinics and maintain a standardized record.
                            </Text>
                            <Text fontSize='2xl' fontWeight='bold' marginBottom='0' marginTop='5px'>
                                ReactJS + ChakraUI
                            </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <Link href='https://devpost.com/software/medivault' isExternal>
                            <Text color='blue.200' fontSize='2xl' align='Center' fontStyle='Italic'>
                                Devpost<ExternalLinkIcon mx='10px' />
                            </Text>
                            </Link>
                        </CardFooter>
                    </Card>


                    <Card maxW='sm' backgroundColor='#44337A' textColor='white' borderRadius='lg'>
                        <CardBody>
                            <Image
                            src='https://files.catbox.moe/z1wfmd.png'
                            borderRadius='lg'
                            />
                            <Stack mt='6' spacing='3'>
                            <Heading size='xl'>BenchPool</Heading>
                            <Text>
                            A resource-sharing portal for resource sharing among companies by providing a platform for posting and booking surplus resources like engineers, seats, and licenses, fostering collaboration and resource optimization.
                            </Text>
                            <Text fontSize='2xl' fontWeight='bold' marginBottom='0' marginTop='5px'>
                                Django + BootStrap
                            </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <Link href='https://github.com/gbijwe/learn_django' isExternal>
                            <Text color='blue.200' fontSize='2xl' align='Center' fontStyle='Italic'>
                                Github<ExternalLinkIcon mx='10px' />
                            </Text>
                            </Link>
                        </CardFooter>
                    </Card>


                    <Card maxW='sm' backgroundColor='#44337A' textColor='white' borderRadius='lg'>
                        <CardBody>
                            <Image
                            src='https://files.catbox.moe/66slha.png'
                            borderRadius='lg'
                            />
                            <Stack mt='6' spacing='3'>
                            <Heading size='xl'>RecycleHub</Heading>
                            <Text>
                            RecycleHub is a specialized hazardous waste management platform offering streamlined services, rewards for collectors, and regulatory compliance, it's a technological solution for sustainable waste management.
                            </Text>
                            <Text fontSize='2xl' fontWeight='bold' marginBottom='0' marginTop='5px'>
                                NextJS + ChakraUI 
                            </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <Link href='https://recyclehub-ts.vercel.app/' isExternal>
                            <Text color='blue.200' fontSize='2xl' align='Center' fontStyle='Italic'>
                                Check it out!<ExternalLinkIcon mx='10px' />
                            </Text>
                            </Link>
                        </CardFooter>
                    </Card>
                </div>
            </div>
            </section>
        </>
    )
};

export default Projects;