import React, { useEffect, useRef } from 'react';
import Resume from '../assets/gauravbijwe2024.pdf';

function About() {

    const NAME_element = useRef()
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        
    function NAME_shuffle(iter_inc = 5) {
        let element = NAME_element.current;
        let iterations = 0;
        const interval = setInterval(() => {
            element.innerText = element.innerText.split("")
            .map((letter, index) => {
                if (index < iterations) {
                    return element.dataset.value[index];
                } else {
                    return letters[Math.floor(Math.random() * 26)];
                }
            })
            .join("");
            if (iterations >= element.dataset.value.length) {
                clearInterval(interval);
            }
            iterations += 1 / iter_inc;
        }, 18);
    }
        
    useEffect(() => {

        setTimeout(() => NAME_shuffle(5));
        
    }, []);

    return (
        <section id="about" className="bg-sky-50">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1> Hi, I'm </h1>
                    <h1 data-value="Gaurav Bijwe" className="name title-font text-4xl md:text-6xl my-5 font-mono font-semibold text-black" onMouseOver={(ev) => NAME_shuffle()} ref={NAME_element}>
                        Gaurav Bijwe
                    </h1>
                    <h1 className="hidden lg:inline-block text text-3xl font-light">
                        I like building apps!
                    </h1>
                    <small className="hidden lg:inline-block mb-8 leading-relaxed italic">
                        Even though it's quite frustrating at times ;-)
                    </small>
                    <a href={Resume} download="MyResume" target='_blank' type="button" className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 rounded-full text-sm px-5 py-2.5 text-center mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 font-bold">
                        <button>Download Resume</button>
                    </a>
                </div>
                <div className="lg:max-w-lg md:w-1/3 w-4/6">
                    <img
                        className="object-cover object-center rounded"
                        alt="hero"
                        src="https://avatars.githubusercontent.com/u/120776034?v=4"
                    />
                </div>

            </div>
        </section>
    );
}

export default About;
